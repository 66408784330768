<template>
  <div class="plantNo-dialog-pln">
    <div class="dialog-wrap-center-pln">
      <div class="dialog-cover-center-pln" v-if="isShow"></div>
      <transition name="dropc">
        <div class="dialog-content-center-pln" :style="[dialogSize]" v-if="isShow">
          <div class="top-title-center-pln" :style="{ width: dialogSize.width }">
            <span class="plantNo-title-pln">厂号选择</span>
            <div class="plantNo-btn-group-pln">
              <span class="plantNo-sure-pln" @click="sure" style="margin-right: 10px">确认</span>
              <span class="plantNo-cancel-pln" @click.stop="closeMyself">取消</span>
            </div>
          </div>
          <section class="customer-section-pln" v-if="custData.length > 0">
            <div class="plantNo-section-title-pln">自定义厂号</div>
            <div class="customer-plantNO-pln">
              <li v-for="(item, index) in custData" :key="index">
                <span>{{ item }}</span>
                <img @click="deleteCustom(index)" src="../../../../../assets/images/plantNo-delete.png" alt="" class="delete-customer-item-pln" />
              </li>
            </div>
          </section>
          <section class="select-option-pln">
            <div class="plantNo-section-title-pln">在华注册工厂</div>
            <ul
              v-if="plantNoOption.length > 0"
              :class="{
                'no-customer': custData.length == 0,
                multipy: plantNoOption.length > 35,
              }"
            >
              <li @click="selectOption(item, index)" v-for="(item, index) in plantNoOption" :class="{ selected: item.selected }" :key="index">
                <span>{{ item.label }}</span>
              </li>
            </ul>
            <div v-else class="no-data">暂无数据</div>
          </section>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '724',
    },
    showType: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: '538',
    },
    plantNodata: {
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      custom: false,
      dialogSize: {
        width: this.width + 'px',
        height: this.height + 'px',
      },
      custData: [],
      plantNoOption: [],
    };
  },
  mounted() {
    this.handleShow();
  },
  methods: {
    closeMyself() {
      this.$emit('on-close');
    },
    handleShow() {
      let plantNoArr = [];
      if (this.plantNodata.plantNo) {
        plantNoArr = this.plantNodata.plantNo.split('、');
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if (!that.plantNodata.category || !that.plantNodata.country) {
        return;
      }
      that.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryPlantNo',
        data: {
          category: that.plantNodata.category,
          country: that.plantNodata.country,
        },
        loading: false,
        success: (res) => {
          if (res.code === 200) {
            if (res.result.length > 0) {
              res.result.forEach((item, index) => {
                that.plantNoOption.push({
                  label: item,
                  value: index,
                  selected: false,
                });
              });
              if (that.plantNoOption.length > 0) {
                plantNoArr.forEach((item2) => {
                  const isIndex = that.plantNoOption.findIndex((item3) => {
                    return item2 === item3.label;
                  });
                  if (isIndex > -1) {
                    that.plantNoOption[isIndex].selected = true;
                  }
                  if (isIndex === -1) {
                    if (item2) {
                      that.custData.push(item2);
                    }
                  }
                });
              }
            }
          }
        },
      });
    },
    selectOption(item, index) {
      const selectArr = [];
      this.plantNoOption.forEach((item) => {
        if (item.selected) {
          selectArr.push('1');
        }
      });
      let len = 0;
      if (this.custData && this.custData.length > 0) {
        len += Number(this.custData.length);
      }
      if (selectArr && selectArr.length > 0) {
        len += Number(selectArr.length);
      }
      if (this.showType === 1) {
        if (len < 8) {
          this.plantNoOption[index].selected = !this.plantNoOption[index].selected;
        }
        if (len === 8) {
          if (item.selected) {
            this.plantNoOption[index].selected = !this.plantNoOption[index].selected;
          }
        }
      }
      if (this.showType === 2 || this.showType === 3) {
        if (this.custData.length > 0) {
          return;
        }
        if (this.custData.length === 0) {
          this.plantNoOption.forEach((item3, index) => {
            this.plantNoOption[index].selected = false;
          });
          this.plantNoOption[index].selected = true;
        }
      }
    },
    deleteCustom(index) {
      this.custData.splice(index, 1);
    },
    sure() {
      const str = [];
      if (this.custData.length > 0) {
        this.custData.forEach((item) => {
          str.push(item);
        });
      }
      this.plantNoOption.forEach((item) => {
        if (item.selected) {
          str.push(item.label);
        }
      });
      const stringP = str.join('、');
      this.$emit('getPlant', stringP);
      this.$emit('on-close');
    },
  },
};
</script>

<style scoped>
.dropc-enter-active {
  transition: all 0.3s ease;
}
.dropc-leave-active {
  transition: all 0.3s ease;
}
.dropc-enter {
  transform: translateY(-20vh);
}
.dropc-leave-active {
  transform: translateY(-20vh);
}

.dialog-wrap-center-pln {
  position: fixed;
  width: 1px;
  height: 100%;
  z-index: 999;
  right: 0;
}
.dialog-cover-center-pln {
  background: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.top-title-center-pln {
  background: #fff;
  border-bottom: 1px solid #e3e4e6;
  height: 72px;
  line-height: 72px;
  font-size: 16px !important;
  color: #1a1a1a;

  width: 724px;
  display: flex;
  justify-content: space-between;
}
.plantNo-dialog-pln .dialog-cover-center-pln {
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.plantNo-dialog-pln .dialog-content-center-pln {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.plantNo-dialog-pln .top-title-center-pln {
  border-bottom: 1px dashed rgba(227, 228, 229, 1);
  align-items: center;
  position: relative;
}
.dialog-content-center-pln {
  position: fixed;
  background: #fff;
  top: 20vh;
  z-index: 1000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 70px;
  overflow: hidden;
  box-sizing: border-box;
  right: 130px;
  margin: 0 auto;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(230, 230, 230, 1);
  box-shadow: 0px 2px 16px 0px rgba(128, 128, 128, 0.25);
}
.dialog-close-center-pln {
  position: absolute;
  right: 25px;
  top: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 16px !important;
}
.dialog-close-center-pln:hover {
  color: #4fc08d;
}
.plantNo-dialog-pln .top-title-center-pln {
  display: flex;
  padding: 0 20px;
}
.plantNo-dialog-pln .plantNo-title-pln {
  text-align: left;
  font-size: 16px;
  color: #1a1a1a;
}
.plantNo-dialog-pln .plantNo-btn-group-pln {
  display: flex;
}
.plantNo-dialog-pln .plantNo-btn-group-pln span {
  display: inline-block;
  width: 72px;
  height: 32px;
  background: rgba(19, 131, 242, 1);
  border-radius: 2px;
  line-height: 32px;

  color: #fff;
  text-indent: 0;
  text-align: center;
  cursor: pointer;
}
.plantNo-dialog-pln .plantNo-btn-group-pln span.plantNo-cancel-pln {
  background: #d9d9d9;
  color: #808080;
}
.plantNo-dialog-pln .customer-section-pln {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(227, 228, 229, 1);
}
.plantNo-dialog-pln .plantNo-section-title-pln {
  line-height: 56px;
  font-size: 16px;
  color: #1a1a1a;
}
.customer-plantNO-pln li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 8px;
  border: 1px solid rgba(128, 128, 128, 1);
  display: inline-block;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 4px;
}
.customer-plantNO-pln li > span {
  max-width: 57px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}
.customer-plantNO-pln li .delete-customer-item-pln {
  position: relative;
  top: 2px;
  margin-left: 6px;
  cursor: pointer;
}
.customer-plantNO-pln {
  border: 1px solid rgba(227, 228, 229, 1);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0;
  margin-right: 12px;
  padding-right: 10px;
}
.select-option-pln {
  padding-left: 20px;
  padding-right: 20px;
}
.select-option-pln > ul {
  height: 270px;
  overflow: auto;
}
.select-option-pln > ul li {
  font-size: 0;
  display: inline-block;
  width: 118px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #333333;
  border: 1px solid rgba(227, 228, 229, 1);
  border-radius: 5px;
  margin-right: 23px;
  cursor: pointer;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}
.select-option-pln > ul.multipy li {
  margin-right: 17px;
}
.select-option-pln > ul li:nth-child(5n + 5) {
  margin-right: 0;
}
.select-option-pln > ul li span {
  display: inline-block;
  width: 108px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #333333;
  font-size: 14px;
}
.select-option-pln > ul li.selected {
  background: #2878ff;
  color: #fff;
  border-color: #2878ff;
}
.select-option-pln > ul li.selected span {
  color: #fff;
}
.select-option-pln .no-data {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-top: 130px;
}
.select-option-pln ul.no-customer {
  height: 370px;
}
</style>
