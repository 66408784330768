/* eslint-disable @typescript-eslint/no-this-alias */
<template>
  <div class="product-pop-box">
    <el-form
      ref="productPop"
      label-width="78px"
      :model="productForm"
      :show-message="isShowMessage"
      :validate-on-rule-change="false"
      :rules="required === true ? rules : null"
    >
      <!--大类选择 开始------------------------------------------------>
      <!--大类选择 showType=1，2，3期货代采 现货代采、现货质押   showType=4 仓储冷运--------------------------->
      <el-form-item label="大类" prop="category" class="required-icon">
        <el-select v-model="productForm.category" placeholder="请选择大类" :popper-append-to-body="false" style="width: 100%" @change="cateSelect">
          <el-option
            v-for="item in showType == 4 ? Options.goods_category : Options.goods_category_slim"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--大类选择 结束------------------------------------------------>

      <!--原产国选择 开始------------------------------------------------>
      <el-form-item v-if="showType !== 4" label="国   家" prop="country" :class="{ 'required-icon': showType !== 4 }">
        <!--<el-input v-model="productForm.countryName" :maxlength="20" placeholder="请输入原产国" v-if="showType !== 1 && productForm.categoryName === '海鲜'"></el-input>-->
        <el-select
          v-model="productForm.country"
          placeholder="请选择国家"
          class="scrollbar__show"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="countrySelect"
        >
          <el-option :disabled="item.disabled" v-for="item in Options.goods_country" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="国   家" v-if="showType == 4" class="country-delect-box">
        <el-select
          v-model="productForm.country"
          placeholder="请选择原产国"
          clearable
          class="scrollbar__show"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="countrySelect"
        >
          <el-option :disabled="item.disabled" v-for="item in Options.goods_country" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <img src="../../../../../assets/images/img_product_delete.png" alt="" @click="clearCountry" class="search-delete-icon" v-if="productForm.country" />
      </el-form-item>
      <!--原产国选择 结束------------------------------------------------>

      <template v-if="showType !== 4">
        <el-form-item label="厂   号" class="required-icon" prop="plantNo">
          <!--<div class="new-plantNo-component" @click="openPlantNoDialog">{{productForm.plantNo}}</div>  isCountNameChina isCategoryNameSea -->
          <el-input v-model="productForm.plantNo" :maxlength="10" placeholder="请输入厂号" v-if="isCountNameChina && showType !== 1"></el-input>
          <el-input v-model="productForm.plantNo" placeholder="请选择厂号" :maxlength="10" v-else readonly @click="openPlantNoDialog"></el-input>
        </el-form-item>
      </template>
      <template v-if="showType == 4">
        <el-form-item label="厂   号">
          <el-input v-model="productForm.plantNo" :maxlength="10" placeholder="请输入厂号"></el-input>
        </el-form-item>
      </template>
      <!--厂   号选择 结束------------------------------------------------>

      <!--中文品名选择 开始 ------------------------------------------------>
      <template v-if="showType == 1 && !productForm.isMultiplyPlantNo">
        <el-form-item
          label="中文品名"
          class="custom-input-width required-icon"
          prop="nameCn"
          @blur="checkNameFn"
          @input="checkNameFn"
          :class="{ qihuoname: isNameCheckFail }"
        >
          <el-autocomplete
            :maxlength="20"
            v-model="productForm.nameCn"
            placeholder="请输入中文品名"
            :readonly="isNameReadonly || Boolean(productForm.labelId)"
            :disabled="isNameReadonly || Boolean(productForm.labelId)"
            @select="nameSelect"
            :fetch-suggestions="queryNameCnSearchAsyncCn"
            class="product-input-custom-width"
            style="width: 435px"
          ></el-autocomplete>
          <img
            src="../../../../../assets/images/img_product_delete.png"
            alt=""
            @click="resetName"
            class="search-delete-icon"
            v-if="isNameReadonly || Boolean(productForm.labelId)"
          />
        </el-form-item>
      </template>
      <template v-if="showType !== 1 || productForm.isMultiplyPlantNo">
        <el-form-item label="中文品名" prop="nameCn" class="required-icon" @blur="checkNameFn" @input="checkNameFn" :class="{ qihuoname: isNameCheckFail }">
          <el-input v-model="productForm.nameCn" :maxlength="20" placeholder="请输入中文品名"></el-input>
        </el-form-item>
      </template>
      <!--中文品名选择 结束 ------------------------------------------------>

      <!--英文品名选择 开始 ------------------------------------------------>
      <template v-if="showType == 1 && !productForm.isMultiplyPlantNo">
        <el-form-item label="英文品名" class="custom-input-width qihuo" prop="nameEn">
          <el-autocomplete
            :maxlength="100"
            v-model="productForm.nameEn"
            placeholder="请输入英文品名"
            :readonly="isNameReadonly || Boolean(productForm.labelId)"
            :disabled="isNameReadonly || Boolean(productForm.labelId)"
            @select="nameSelect"
            :fetch-suggestions="queryNameCnSearchAsyncEn"
            class="product-input-custom-width"
            style="width: 435px"
          ></el-autocomplete>
          <img
            src="../../../../../assets/images/img_product_delete.png"
            alt=""
            @click="resetName"
            class="search-delete-icon"
            v-if="isNameReadonly || Boolean(productForm.labelId)"
          />
        </el-form-item>
      </template>
      <template v-if="showType == 2 || showType == 3 || productForm.isMultiplyPlantNo">
        <el-form-item label="英文品名" class="qihuo">
          <el-input v-model="productForm.nameEn" placeholder="请输入英文品名" :maxlength="100"></el-input>
        </el-form-item>
      </template>
      <!-- <form-button-group :submit="submitForm('productPop')" :cancel="cancelPro" ></form-button-group> -->
      <div class="form-btn__group">
        <el-button class="form-button" type="primary" @click="submitForm('productPop')">确定</el-button>
        <el-button class="form-button rest" @click="cancelPro">取消</el-button>
      </div>
      <!--英文品名选择 结束 ------------------------------------------------>
      <template v-if="isShowPlantNoDialog">
        <plantNoDialog
          @getPlant="getSelectPlant"
          :showType="showType"
          :isShow="isShowPlantNoDialog"
          @on-close="closPlantNoDialog"
          :plantNodata="productForm"
        ></plantNoDialog>
      </template>
    </el-form>
  </div>
</template>
<script>
import plantNoDialog from './plantNoDialog.vue';
import { store } from '@/store';

export default {
  created() {
    this.$nextTick(function () {
      this.productForm = this.deepClone(this.productDialogData);
      this.comData = this.deepClone(this.productDialogData);

      this.initializeFn();
    });
  },
  props: ['showType', 'itemIndex', 'productDialogData', 'required'],
  data() {
    return {
      productForm: {
        category: '',
        categoryName: '',
        country: '',
        countryName: '',
        id: 0,
        nameCn: '',
        nameEn: '',
        plantNo: '',
        productId: 0,
        unitPrice: '',
        weight: '',
        declarationKeyElements: '', // 申报要素
        packageCount: '', // 件数
        subLevelStandard: '', // 二级标
        labelId: '',
        // 新增
        isValid: true, // 是否通过校验
        hsCode: '', // 标签码
      },
      Options: {
        goods_category_slim: store.state.dict.options.goods_category_slim,

        goods_category: store.state.dict.options.goods_category,

        goods_country: store.state.dict.options.goods_country,
      },
      isNameReadonly: false,
      searchList: [], // 品名 搜索列表
      isShowMessage: true,
      url: '',
      isNameCheckFail: false,
      isShowPlantNoDialog: false,
      comData: {},
      rules: {
        // 检验规则
        category: [{ required: true, message: '请选择大类', trigger: 'blur' }],
        country: [{ required: true, message: '请选择国家', trigger: 'blur' }],
        plantNo: [{ required: true, message: '请选择厂号', trigger: 'blur' }],
        nameCn: [{ required: true, message: '请输入中文品名', trigger: 'blur' }],
      },
    };
  },
  computed: {
    isCountNameChina() {
      return this.productForm.countryName === '中国';
    },
  },
  components: { plantNoDialog },
  methods: {
    // 大类选择事件
    cateSelect(val) {
      this.productForm.categoryName = this.showType === 4 ? this.Options.goods_category[val - 1].label : this.Options.goods_category_slim[val - 1].label;
      this.$refs.productPop.validateField('category');
      if (this.showType !== 4) {
        // 联动， 清空 原厂国
        this.productForm.country = '';
        this.productForm.countryName = '';
        this.productForm.plantNo = '';
        if (this.showType === 1) {
          this.productForm.nameCn = '';
          this.productForm.nameEn = '';
          this.productForm.isDisable = false;
          this.productForm.hsCode = '';
          this.productForm.labelId = null;
          this.productForm.url = '';
          this.isNameReadonly = false;
        }
      } else {
        this.productForm.nameEn = '';
      }
      this.handleOption();
    },
    // 原产国选择事件
    countrySelect() {
      this.$refs.productPop.validateField('country');
      if (this.showType !== 4) {
        // 联动， 清空 厂号
        this.productForm.plantNo = '';
      }
      if (this.showType === 1) {
        this.productForm.nameCn = '';
        this.productForm.nameEn = '';
        this.productForm.isDisable = false;
        this.productForm.hsCode = '';
        this.productForm.labelId = null;
        this.productForm.url = '';
        this.isNameReadonly = false;
      }
      const index = this.Options.goods_country.findIndex((value) => value.value === this.productForm.country);
      this.productForm.countryName = this.Options.goods_country[index].label;
      this.handleOption();
      // countryName
    },
    // 中文品名 选择事件
    nameSelect(item) {
      this.productForm.hsCode = item.hsCode;
      this.productForm.nameCn = item.nameCn;
      this.productForm.nameEn = item.nameEn;
      this.productForm.labelId = item.id;
      this.productForm.isDisable = false;
      this.productForm.url = item.url;
      this.isNameReadonly = true;
    },
    // 初始化数据
    initializeFn() {
      //      this.productForm = this.deepClone(this.originData);
      if (this.showType !== 1) {
        this.productForm.hsCode = '';
      }
      // 处理Option
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.$refs.productPop.clearValidate();
      this.isNameCheckFail = false;
      this.handleOption();
    },
    // 处理Option
    handleOption() {
      // 处理 原产国Option
      if (this.showType !== 4) {
        // 处理 厂号Option
        this.handlePlantNo();
      }
      this.handleCountryOption();
    },
    // 处理 原产国Option
    handleCountryOption() {
      this.getCountryOption();
    },
    handlePlantNo() {
      let plArr = [];
      if (this.productForm.plantNo) {
        plArr = this.productForm.plantNo.split('、');
      }
      if (plArr.length > 1) {
        this.productForm.isMultiplyPlantNo = true;
      } else {
        this.productForm.isMultiplyPlantNo = false;
      }
    },
    // 获取 国家 Option
    getCountryOption() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const category = that.productForm.category;
      if (that.showType !== 4 && !category) {
        that.Options.goods_country = [];
        return;
      }
      if (that.showType === 4) {
        const goodsCountry = store.state.dict.options.goods_country;

        this.Options.goods_country = goodsCountry;
        return;
      }
      let addChina; // 0否， 1是
      if (that.showType === 2 || that.showType === 3) {
        addChina = 1;
      } else {
        addChina = 0;
      }
      that.ajax({
        type: 'POST',
        url: '/malicrm/approval/queryCountry',
        data: {
          category,
          isAddChina: addChina,
        },
        loading: false,
        success: (res) => {
          if (res.code === 200) {
            const courntyOption = [];

            //            // 如果是 期货代采-现货代采，且没有中国option 就把中国项加入
            //            if (that.showType === 2 || that.showType === 3) {
            //              var hasChina = res.result.some((item, index) => {
            //                return item.countryName === '中国';
            //              });
            //              if (!hasChina) {
            //                res.result.push({country: 6, countryName: '中国'});
            //              }
            //            }
            if (res.result.length > 0) {
              res.result.forEach((item, index) => {
                courntyOption.push({
                  value: item.country,
                  label: item.countryName,
                });
                this.Options.goods_country[index] = courntyOption[index];
              });
              that.Options.goods_country.splice(courntyOption.length);
              if (that.productForm.country) {
                const countINdex = that.Options.goods_country.findIndex((item) => {
                  return item.value === that.productForm.country;
                });
                if (countINdex === -1) {
                  that.Options.goods_country.push({
                    value: that.productForm.country,
                    label: that.productForm.countryName,
                    disabled: true,
                  });
                }
              }
            }
            if (res.result.length === 0) {
              that.Options.goods_country = [];
            }
          } else {
            that.errorTip(res.message);
          }
        },
      });
    },
    // 中文远程搜索
    queryNameCnSearchAsyncCn(queryNameCn, cb) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.ajax({
        type: 'POST',
        url: '/malicrm/label/queryProductLabel',
        data: {
          category: that.productForm.category, // 1 ,
          country: that.productForm.country, // 19,
          nameCn: queryNameCn, // that.productForm.nameCn,
          nameEn: '', // that.productForm.nameEn,
          plantNo: that.productForm.plantNo, // plantNo
        },
        loading: false,
        isUnMusk: true,
        success: (res) => {
          if (res.code === 200) {
            that.searchList = res.result;
            if (that.searchList.length > 0) {
              that.searchList.forEach((item, index) => {
                that.searchList[index].value = that.searchList[index].nameCn;
              });
            }
            cb(that.searchList);
          }
        },
      });
    },
    // 英文远程搜索
    queryNameCnSearchAsyncEn(queryNameEn, cb) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.ajax({
        type: 'POST',
        url: '/malicrm/label/queryProductLabel',
        data: {
          category: that.productForm.category, // 1 ,
          country: that.productForm.country, // 19,
          nameCn: '', // that.productForm.nameCn,
          nameEn: queryNameEn, // that.productForm.nameEn,
          plantNo: that.productForm.plantNo, // plantNo
        },
        loading: false,
        isUnMusk: true,
        success: (res) => {
          if (res.code === 200) {
            that.searchList = res.result;
            if (that.searchList.length > 0) {
              that.searchList.forEach((item, index) => {
                that.searchList[index].value = that.searchList[index].nameEn;
              });
            }
            cb(that.searchList);
          }
        },
      });
    },
    // 删除品名 中文 英文
    resetName() {
      this.productForm.hsCode = '';
      this.productForm.nameCn = '';
      this.productForm.nameEn = '';
      this.productForm.labelId = '';
      this.productForm.isDisable = false;
      this.isNameReadonly = false;
    },
    // 取消选择
    cancelPro() {
      this.$emit('on-close', this.itemIndex);
    },
    // 确定选择
    submitForm() {
      this.$refs.productPop.validate((valid) => {
        if (valid) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const that = this;
          const comData = this.comData;
          if (this.productForm.isDisable) {
            if (
              String(this.productForm.labelId) === String(comData.labelId) &&
              this.productForm.nameCn === comData.nameCn &&
              this.productForm.nameEn === comData.nameEn
            ) {
              this.productForm.category = '';
              this.productForm.categoryName = '';
              this.productForm.country = '';
              this.productForm.countryName = '';
              this.productForm.nameCn = '';
              this.productForm.nameEn = '';
              this.productForm.plantNo = '';
              this.productForm.isMultiplyPlantNo = false;
              this.productForm.isValid = false;
              this.productForm.goodsInfoFormat = '';
              this.productForm.isPlantNoInOption = false;
              this.productForm.hsCode = '';
              this.productForm.isDisable = false;
              this.productForm.labelId = '';
              this.$emit('changePopItem', this.productForm, this.itemIndex);

              this.$emit('close-pop', this.itemIndex);
              return;
            }
          }
          that.productForm.isValid = true;
          const goodsInfoFormat = that._tableGoodsInfo(that.productForm);
          that.productForm.goodsInfoFormat = goodsInfoFormat;
          if (that.showType === 4) {
            that.productForm.hsCode = '';
            that.productForm.nameEn = '';
          }
          if (that.showType === 2 || that.showType === 3) {
            that.productForm.hsCode = '';
          }
          this.$emit('changePopItem', this.productForm, this.itemIndex);
          this.$emit('on-close', this.itemIndex);
        } else {
          console.log('err');
        }
      });
    },
    // 处理货物信息拼接
    _tableGoodsInfo(item) {
      const goods = Object.assign({}, item);
      let str = '';
      str += goods.categoryName ? goods.categoryName : '';
      str += goods.countryName ? '_' + goods.countryName : '';
      str += goods.plantNo ? '_' + goods.plantNo : '';
      str += goods.nameCn ? '_' + goods.nameCn : '';
      if (this.showType !== 4) {
        str += goods.nameEn ? '_' + goods.nameEn : '';
      }
      return str;
    },
    // 用来判断被拷贝对象数据类型的函数
    checkType(data) {
      return Object.prototype.toString.call(data).slice(8, -1);
    },
    // 实现深度拷贝的函数
    deepClone(target) {
      let result;
      const targetType = this.checkType(target);
      if (targetType === 'Object') {
        // 只有对象和数组才进行深度拷贝
        result = {};
      } else if (targetType === 'Array') {
        result = [];
      } else {
        return target;
      }
      for (const i in target) {
        // i为对象的key或数组的下标
        const value = target[i];
        const valueType = this.checkType(value);
        if (valueType === 'Object' || valueType === 'Array') {
          result[i] = this.deepClone(value);
        } else {
          result[i] = value;
        }
      }
      return result; // 返回最终的结果
    },
    checkNameFn() {
      if (this.productForm.nameCn) {
        this.isNameCheckFail = false;
      } else {
        this.isNameCheckFail = true;
      }
    },
    openPlantNoDialog() {
      if (!this.showType) {
        this.errorTip('请先选择业务');
        return;
      }
      this.isShowPlantNoDialog = true;
    },
    closPlantNoDialog() {
      this.isShowPlantNoDialog = false;
    },
    getSelectPlant(str) {
      // productForm.isMultiplyPlantNo
      const plArr = str.split('、');
      if (plArr.length > 1) {
        this.productForm.isMultiplyPlantNo = true;
      } else {
        this.productForm.isMultiplyPlantNo = false;
      }
      if (str === this.productForm.plantNo) {
        return;
      }
      this.productForm.plantNo = str;
      this.productForm.nameCn = '';
      this.productForm.nameEn = '';
      this.productForm.isDisable = false;
      this.productForm.hsCode = '';
      this.productForm.labelId = null;
      this.productForm.url = '';
      this.isNameReadonly = false;
      this.$refs.productPop.validateField('plantNo', () => {
        return true;
      });
    },
    clearCountry() {
      this.productForm.country = '';
      this.productForm.countryName = '';
    },
  },
};
</script>
<style lang="scss">
.product-pop-box .custom-input-width .el-form-item__content {
  position: relative;
}
.product-pop-box .custom-input-width .el-form-item__content .search-delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.product-pop-box {
  box-sizing: border-box;
  padding: 16px 4px 16px 12px;
}
.product-pop-box .el-form-item__label {
  text-align: justify;
  text-align-last: justify;
  position: relative;
  transform: translateX(-8px);
  color: #333;
}
.product-pop-box .required-icon .el-form-item__label:after {
  content: '*';
  display: inline-block;
  position: absolute;
  color: #ff6c2b;
  font-size: 24px;
  vertical-align: middle;
  top: 5px;
  right: 1px;
}
.product-pop-box .button-box {
  margin-top: 0;
  margin-left: 205px;
  margin-bottom: -8px;
}
.product-pop-box .form-btn__group {
  text-align: center;
  margin-left: 210px;
  margin-bottom: -8px;
}
.product-pop-box .form-button {
  padding: 0;
  border: none;
  font-size: 16px;
  color: #fff;
  background: #2878ff;
  width: 140px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
  &.rest {
    border: 1px solid #2878ff;
    color: #2878ff;
    background: transparent;
    line-height: inherit;
  }
  &.primary {
    color: #fff;
    background: #2878ff;
  }
}
.product-pop-box .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  opacity: 0;
  display: none;
}
.old-data-box {
  position: relative;
  .old-data-son {
    position: absolute;
    top: 8px;
    left: 93px;
    z-index: 99;
  }
}
.el-form-item.qihuo .el-input__inner {
  border-color: #dcdfe6;
}
.el-form-item.qihuoname .el-input__inner {
  border-color: #f56c6c;
}
.new-plantNo-component {
  width: 434px;
  height: 32px;
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.country-delect-box .el-form-item__content {
  position: relative;
}
.country-delect-box .el-form-item__content img.search-delete-icon {
  position: absolute;
  top: -2px;
  right: 0;
  cursor: pointer;
}
.el-form-item.required-icon.is-required.el-form-item--small {
  margin-bottom: 28px !important;
}
</style>
