/* eslint-disable @typescript-eslint/no-empty-function */
export default {
  data() {
    return {
      productTableWidth: 1000,
      productDialogData: {},
      itemIndex: 0,
      xhfwJrTable: [
        {
          configMin: 1,
          configMax: 45,
          rate: null,
          minUseDays: 15,
        },
        {
          configMin: 46,
          configMax: 90,
          rate: null,
          minUseDays: null,
        },
        {
          configMin: 91,
          configMax: null,
          rate: null,
          minUseDays: null,
        },
      ],
    };
  },
  filters: {
    appendUnit(val) {
      if (val) {
        return val + '%';
      } else {
        return '-';
      }
    },
  },
  computed: {
    // 判断是否展示采购字段
    isShowPurchaseTd() {
      const item = this.supplierdataList.find((item) => item.id === this.formData.productQHDCDto.qhdcSupplierId);
      if (item) {
        return item.supplierName === 'Epic Meats Group Limited';
      }
      // 隐藏采购后，清空采购字段值 TODO
      this.formData.productQHDCDto.userIds = null;
      return false;
    },
    unit() {
      const str = ['美元', '美元', '元', '元', ''];
      if (this.showType === 1) {
        if (this.formData.productQHDCDto.qhdcTradeCurrency !== '') {
          const index = this.formData.productQHDCDto.qhdcTradeCurrency - 1;
          return this.Options.trade_currency[index].label;
        } else {
          return '美元';
        }
      } else {
        return str[Number(this.showType)];
      }
    },

    goodsInfoWidth() {
      if (this.showType === 1) {
        return 300;
      }
    },
  },
  methods: {
    changeSpellArkContainerOrDate(e) {
      let value = e.target.value;
      value = value.replace(/[a-zA-Z]/g, '');
      this.formData.productQHDCDto.qhdcShippingDate = value;
    },
    changeSubLevelStandard(e, index, objectName) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\u4E00-\u9FA5\d\x21-\x7e\uFE30-\uFFA0]/g, ''); // 清除中文英文,符号数字以外的字符
      this.formData.productGoodsDtoList[index][objectName] = value;
    },
    changeContacts(e, obj, objectName) {
      // 联系方式
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d\x21-\x7e\uFE30-\uFFA0]/g, ''); // 清除中文英文,符号数字以外的字符
      this.formData[obj][objectName] = value;
    },
    jrDayFormat(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
      value = Number(value);
      if (value === 0) {
        value = null;
      }
      item[name] = value;
    },
    jrRateFormat(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99.99) {
        // 超过阈值控制为最大值
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (value === '' || value === null) {
        value = '';
      } else {
        value = Number(value);
      }
      item[name] = value;
    },
    changePhone(e, obj, objectName) {
      let value = e.target.value;
      value = value.replace(/[a-zA-Z\u4E00-\u9FA5]/g, ''); // 清除中文英文以外的字符
      this.formData[obj][objectName] = value;
    },
    changeChinese(e, obj, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除中文以外的字符
      this.formData[obj][objectName] = value;
    },
    changeDays(e, obj, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
      this.formData[obj][objectName] = value;
    },
    changePackageCount(e, index, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符

      this.formData.productGoodsDtoList[index][objectName] = value;
    },
    changePaymentRatio(e, obj, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        // 超过阈值控制为最大值
        value = 100;
      }
      this.formData[obj][objectName] = value;
    },
    changeAmount(e, obj, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === '0') {
          if (objectName === 'qhdcCgAgencyFee' && value.length > 1) {
            value = value.substr(1);
          } else if (objectName === 'qhdcCgAgencyFee' && value.length === 1) {
            value = '0';
          } else {
            value = value.substr(1);
          }
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      this.formData[obj][objectName] = value;
    },
    changeWeight(e, index, objectName) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      if (objectName === 'unitPrice') {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
        if (value >= 10000) {
          const index = value.indexOf('.');
          if (index > -1) {
            const intStr = value.substr(0, index - 1);
            const dotStr = value.substr(index);
            value = intStr + dotStr;
          } else {
            value = value.substr(0, 4);
          }
        }
      } else {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      }

      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }

      this.formData.productGoodsDtoList[index][objectName] = value;
      this.totalPricedata = this.totalPrice();
      this.totalWeightData = this.totalWeight();
    },
    valPayFeeDouble(val, prop, index) {
      let value = val.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(\d\d)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) == 0) {
          // eslint-disable-line
          value = value.substr(1);
        }
        // 如果数据是001这种类型，还需要再执行一次。
        if (value.substr(0, 1) == 0) {
          // eslint-disable-line
          value = value.substr(1);
        }
        value = String(parseInt(value));
      }
      if (value.indexOf('.') >= 0 && value !== '') {
        value = String(parseFloat(value).toFixed(2));
      }
      if (value.indexOf('.') === 0) {
        value = '0' + value.substr(0, 3);
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99.99) {
        value = 99.99;
      }
      if (parseFloat(value)) {
        this.formData.lumpDtos[index][prop] = value;
      } else {
        this.formData.lumpDtos[index][prop] = null;
      }
    },
    isNullData(data) {
      return data === '' || data === null;
    },
    // 校验所有的必填项
    validateRules(data) {
      const status = true;
      if (data.category === 1) {
        // 期货
        if (this.isNullData(data.productQHDCDto.qhdcPortArrival)) {
          this.errorTip('目的港不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcShippingDate)) {
          this.errorTip('船期不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcIncoterm)) {
          this.errorTip('Incoterm不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcContainerType)) {
          this.errorTip('柜型不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcIsNeedInsurance)) {
          this.errorTip('是否买保险不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcDepositRate)) {
          this.errorTip('保证金比例不可为空');
          return false;
        }
        if (this.isNullData(data.settleType)) {
          this.errorTip('计费方式不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.payDepositDate)) {
          this.errorTip('支付保证金/定金的时间不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.category)) {
          this.errorTip('大类不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.country)) {
          this.errorTip('国家不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.plantNo)) {
          this.errorTip('厂号不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcSupplierId)) {
          this.errorTip('供应商不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcSupplierPaymentRatio)) {
          this.errorTip('供应商预付比例不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcPayType)) {
          this.errorTip('付款方式不可为空');
          return false;
        }
        if (this.isNullData(data.productQHDCDto.qhdcConsigneeType)) {
          this.errorTip('抬头类型不可为空');
          return false;
        }
        if (data.productGoodsDtoList.length <= 0) {
          this.errorTip('请添加货物信息');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.nameCn === null || item.nameCn === '';
          })
        ) {
          this.errorTip('中文品名均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.unitPrice === null || item.unitPrice === '';
          })
        ) {
          this.errorTip('价格(美元/kg)均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.weight === null || item.weight === '';
          })
        ) {
          this.errorTip('重量(kg)均不可为空');
          return false;
        }
        if (data.isNeedQg === true) {
          if (this.isNullData(data.agencyId)) {
            this.errorTip('报关行/代理不可为空');
            return false;
          }
        }
        if (data.settleType === 2) {
          if (
            data.lumpDtos.some((item) => {
              return item.fee === null || item.fee === '';
            })
          ) {
            this.errorTip('收费标准-服务费(元/公斤)均不可为空');
            return false;
          }
        }
      }
      if (data.category === 2 || data.category === 3) {
        if (this.isNullData(data.productXHFWDto.xhfwStorageName)) {
          this.errorTip('现存冷库名称名称不可为空');
          return false;
        }
        if (this.isNullData(data.productXHFWDto.xhfwContainerNo)) {
          this.errorTip('柜号不可为空');
          return false;
        }
        if (this.isNullData(data.productXHFWDto.xhfwDepositRate)) {
          this.errorTip('保证金比例不可为空');
          return false;
        }
        if (this.isNullData(data.productXHFWDto.xhfwUpstreamName)) {
          this.errorTip('上游名称不可为空');
          return false;
        }
        if (this.isNullData(data.productXHFWDto.xhfwUpstreamContactsName)) {
          this.errorTip('上游联系人不可为空');
          return false;
        }
        if (data.productGoodsDtoList.length <= 0) {
          this.errorTip('请添加货物信息');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.category === null || item.category === '';
          })
        ) {
          this.errorTip('大类均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.country === null || item.country === '';
          })
        ) {
          this.errorTip('国家均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.plantNo === null || item.plantNo === '';
          })
        ) {
          this.errorTip('厂号均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.nameCn === null || item.nameCn === '';
          })
        ) {
          this.errorTip('中文品名均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.packageCount === null || item.packageCount === '';
          })
        ) {
          this.errorTip('件数均不可为空');
          return false;
        }

        if (
          data.productGoodsDtoList.some((item) => {
            return item.unitPrice === null || item.unitPrice === '';
          })
        ) {
          this.errorTip('价格(美元/kg)均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.weight === null || item.weight === '';
          })
        ) {
          this.errorTip('重量(kg)均不可为空');
          return false;
        }
        if (data.isNeedXhfw === true) {
          if (this.isNullData(data.productXHFWDto.xhfwServiceFee)) {
            this.errorTip('服务费用-服务费（元/吨）不可为空');
            return false;
          }
        }
        if (data.isNeedJr === true) {
          if (!this.testJrRules()) {
            return false;
          }
        }
      }
      if (data.category === 4) {
        if (data.productGoodsDtoList.length <= 0) {
          this.errorTip('请添加货物信息');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.category === null || item.category === '';
          })
        ) {
          this.errorTip('大类均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.nameCn === null || item.nameCn === '';
          })
        ) {
          this.errorTip('中文品名均不可为空');
          return false;
        }
        if (
          data.productGoodsDtoList.some((item) => {
            return item.weight === null || item.weight === '';
          })
        ) {
          this.errorTip('重量(kg)均不可为空');
          return false;
        }
      }

      if (data.isNeedCc === true && data.category !== 1) {
        if (this.isNullData(data.productCCDto.ccStorageName)) {
          this.errorTip('仓储服务-仓库名称不可为空');
          return false;
        }
        if (this.isNullData(data.productCCDto.ccAddressCity)) {
          this.errorTip('仓储服务-仓库地址不可为空');
          return false;
        }
        if (this.isNullData(data.productCCDto.ccAddressDetail)) {
          this.errorTip('仓储服务-仓库详细地址不可为空');
          return false;
        }
        if (this.isNullData(data.productCCDto.ccInstoreDate)) {
          this.errorTip('仓储服务-预计入库时间不可为空');
          return false;
        }
        if (this.isNullData(data.productCCDto.ccStorageDays)) {
          this.errorTip('仓储服务-期望存储天数不可为空');
          return false;
        }
      }
      if (data.isNeedLy === true) {
        if (this.isNullData(data.productLYDto.lyDepartStorageName)) {
          this.errorTip('冷运服务-发货冷库名称不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyDepartProvince)) {
          this.errorTip('冷运服务-发货冷库地址不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyDepartDetail)) {
          this.errorTip('冷运服务-发货冷库详细地址不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyArriveStorageName)) {
          this.errorTip('冷运服务-收货冷库名称不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyArriveProvince)) {
          this.errorTip('冷运服务-收货冷库地址不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyArriveDetail)) {
          this.errorTip('冷运服务-收货冷库详细地址不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyDepartDate)) {
          this.errorTip('冷运服务-发货时间');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyLoadingContactsName)) {
          this.errorTip('冷运服务-装货联系人不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyLoadingContactsTelephone)) {
          this.errorTip('冷运服务-装货联系电话不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyUnloadingContactsName)) {
          this.errorTip('冷运服务-卸货联系人不可为空');
          return false;
        }
        if (this.isNullData(data.productLYDto.lyUnloadingContactsTelephone)) {
          this.errorTip('冷运服务-卸货联系电话不可为空');
          return false;
        }
      }
      return status;
    },
    getXhfwAddress(data) {
      this.formData.productXHFWDto.xhfwStorageProvince = data.province.name;
      this.formData.productXHFWDto.xhfwStorageCity = data.city.name;
      this.formData.productXHFWDto.xhfwStorageDistrict = data.county.name;
      this.formData.productXHFWDto.xhfwStorageProvinceId = data.province.id;
      this.formData.productXHFWDto.xhfwStorageCityId = data.city.id;
      this.formData.productXHFWDto.xhfwStorageDistrictId = data.county.id;
    },
    getCcAddress(data) {
      this.formData.productCCDto.ccAddressProvince = data.province.name;
      this.formData.productCCDto.ccAddressCity = data.city.name;
      this.formData.productCCDto.ccAddressDistrict = data.county.name;
      this.formData.productCCDto.ccAddressProvinceId = data.province.id;
      this.formData.productCCDto.ccAddressCityId = data.city.id;
      this.formData.productCCDto.ccAddressAreaId = data.county.id;
    },
    getLyDepart(data) {
      this.formData.productLYDto.lyDepartProvince = data.province.name;
      this.formData.productLYDto.lyDepartCity = data.city.name;
      this.formData.productLYDto.lyDepartDistrict = data.county.name;
      this.formData.productLYDto.departProvinceId = data.province.id;
      this.formData.productLYDto.departCityId = data.city.id;
      this.formData.productLYDto.departAreaId = data.county.id;
    },
    getLyArrive(data) {
      this.formData.productLYDto.lyArriveProvince = data.province.name;
      this.formData.productLYDto.lyArriveCity = data.city.name;
      this.formData.productLYDto.lyArriveDistrict = data.county.name;
      this.formData.productLYDto.arriveProvinceId = data.province.id;
      this.formData.productLYDto.arriveCityId = data.city.id;
      this.formData.productLYDto.arriveAreaId = data.county.id;
    },
    // showPop 缓存item数据
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    popShowFn() {},
    // hidePop
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    popHideFn() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    closePop() {},
    // 处理货物信息拼接
    _tableGoodsInfo(item) {
      const goods = Object.assign({}, item);
      let str = '';
      str += goods.categoryName ? goods.categoryName : '';
      if (goods.categoryName) {
        str += goods.countryName ? '_' + goods.countryName : '';
      } else {
        str += goods.countryName ? goods.countryName : '';
      }
      str += goods.plantNo ? '_' + goods.plantNo : '';
      str += goods.nameCn ? '_' + goods.nameCn : '';
      if (this.showType !== 4) {
        str += goods.nameEn ? '_' + goods.nameEn : '';
      }
      return str;
    },
    // 处理货物表格 长度
    getTableWidth() {
      if (this.showType === 4) {
        this.productTableWidth = 960;
      } else if (this.showType === 2 || this.showType === 3) {
        this.productTableWidth = 1400;
      } else {
        this.productTableWidth = 1040;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cateBussinessFocus(val) {},
    changePopItem(item, index) {
      this.formData.productGoodsDtoList[index] = item;

      const params = {
        category: item.category,
        country: item.country,
        plantNo: item.plantNo,
      };
      if (this.formData.category === 1 && index === 0) {
        this.getPlantName(params); // 自动获取工厂全称信息
      }
    },
    getPlantName(data) {
      // 自动获取工厂全称信息
      this.ajax({
        type: 'POST',
        data: data,
        url: '/malicrm/approval/queryPlantName',
        loading: false,
        success: (res) => {
          this.formData.productQHDCDto.qhdcPlantName = res.result;
        },
      });
    },
    checkNewGoods() {
      const data = this.formData.productGoodsDtoList;
      const resultArr = [];
      data.forEach((item, index) => {
        let result = false;
        if (this.showType !== 4) {
          if (item.category && item.country && item.plantNo && item.nameCn) {
            result = true;
          }
        } else {
          if (item.category && item.nameCn) {
            result = true;
          }
        }
        if (result) {
          this.formData.productGoodsDtoList[index].isValid = true;
        } else {
          this.formData.productGoodsDtoList[index].isValid = false;
        }
        resultArr.push(result);
      });
      return resultArr.every((item) => {
        return item;
      });
    },
    clickProductInfo(item, index) {
      if (!this.showType) {
        this.errorTip('请先选择业务');
        return;
      }
      this.itemIndex = index;
      this.productDialogData = item;
      this.isShowProductDialog = true;
    },
    closeProductDialog() {
      this.isShowProductDialog = false;
    },
  },
};
