<template>
  <div class="dialog-left-center">
    <div class="dialog-wrap-center-product">
      <div class="dialog-cover-product" v-if="isShow" @click.stop="closeMyself"></div>
      <div class="dialog-cover-center-product" v-if="isShow" @click.stop="closeMyself"></div>
      <transition name="dropc">
        <div class="dialog-content-center-product" v-if="isShow">
          <div class="top-title-center-product">
            {{ title }}
            <span class="dialog-close-center-product el-icon-close" @click.stop="closeMyself"></span>
          </div>
          <div>
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '货物信息',
    },
    width: {
      type: [String, Number],
      default: '578',
    },
    height: {
      type: [String, Number],
      default: '400',
    },
  },
  data() {
    return {
      custom: false,
      dialogSize: {
        width: this.width + 'px',
        height: this.height + 'px',
      },
    };
  },
  methods: {
    closeMyself() {
      this.$emit('on-close');
    },
  },
};
</script>

<style scoped>
.dialog-cover-product {
  background: #000;
  opacity: 0.4;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dropc-enter-active {
  transition: all 0.3s ease;
}
.dropc-leave-active {
  transition: all 0.3s ease;
}
.dropc-enter {
  transform: translateY(-20vh);
}
.dropc-leave-active {
  transform: translateY(-20vh);
}

.dialog-wrap-center-product {
  position: fixed;
  width: 1px;
  height: 100%;
  z-index: 999;
  right: 0;
}
.dialog-cover-center-product {
  background: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.top-title-center-product {
  background: #fff;
  border-bottom: 1px solid #e3e4e6;
  height: 50px;
  line-height: 50px;
  font-size: 16px !important;
  color: #333;
  text-indent: 20px;
  position: absolute;
  top: 0;
  right: 0 !important;
  width: 100%;
  box-sizing: border-box;
}
.dialog-content-center-product {
  position: fixed;
  background: #fff;
  top: 20vh;
  right: 180px;
  z-index: 1000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 70px;
  /*overflow: hidden;*/
  box-sizing: border-box;
  /*left:0;*/
  /*margin:0 auto;*/
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(230, 230, 230, 1);
  box-shadow: 0px 2px 16px 0px rgba(128, 128, 128, 0.15);
  box-sizing: border-box;
  padding-bottom: 12px;
}
.dialog-left-center .dialog-content-center-product {
  right: 200px;
}
.dialog-close-center-product {
  position: absolute;
  right: 25px;
  top: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 16px !important;
}
.dialog-close-center-product:hover {
  color: #4fc08d;
}
.dialog-left-center .dialog-content-center-product {
  overflow: inherit;
}
</style>
